<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-select
                  v-model="province"
                  :items="provinces"
                  label="Province"
                  data-vv-name="select"
                  @change="updateLngLat"
                  required
              ></v-select>
              <v-text-field
                  v-model="opts.LongitudeMin"
                  color="light-blue"
                  label="Longitude Minimum"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="opts.LongitudeMax"
                  color="light-blue"
                  label="Longitude Maximum"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="opts.LatitudeMin"
                  color="light-blue"
                  label="Latitude Minimum"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="opts.LatitudeMax"
                  color="light-blue"
                  label="Latitude Maximum"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="opts.PriceMin"
                  color="light-blue"
                  label="Minimum Price"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="opts.PriceMax"
                  color="light-blue"
                  label="Maximum Price"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="opts.BuildingTypeId"
                  color="light-blue"
                  label="Building Type"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="opts.CurrentPage"
                  color="light-blue"
                  label="Current Page"
                  required
              ></v-text-field>
              <v-select
                  v-model="dataNeeded"
                  :items="scrapeTypes"
                  label="Data Needed"
                  data-vv-name="select"
                  required
              ></v-select>
            </v-col>
          </v-row>
          <v-btn
              v-if="dataNeeded"
              @click="getDataButtonPressed"
          >
            Get Data
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="8">
        <v-fade-transition>
          <v-card class="w-100" v-if="result">
            <v-card-title>
              Results
              <v-spacer></v-spacer>
              <div>
                <v-btn class="mr-2" color="primary" @click="getPrevPage" outlined :disabled="opts.CurrentPage === 1">
                  Prev
                </v-btn>
                <v-btn @click="getNextPage" color="primary">
                  Next
                </v-btn>
              </div>
            </v-card-title>
            <v-card-subtitle>
              <span>Records Per Page <strong>{{ result.length }}</strong></span>
              &nbsp;•&nbsp;
              <span>Total Records <strong>{{ rawResult.Paging.TotalRecords }}</strong></span>
            </v-card-subtitle>
            <v-card-text v-if="dataNeeded !== 'LatLng + Address'">
              <!--              {{ rawResult.Paging }}-->
              <div v-for="item in result"
                   :key="item"
                   class="w-100"
              >
                {{ item }}
              </div>
            </v-card-text>
            <v-progress-linear :active="loading" indeterminate/>
            <v-simple-table v-if="dataNeeded === 'LatLng + Address'">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Price
                  </th>
                  <th class="text-left">
                    Lat/Lng
                  </th>
                  <th class="text-left">
                    Street Address
                  </th>
                  <th class="text-left">
                    Area
                  </th>
                  <th class="text-left">
                    Postal Code
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in result"
                    :key="index"
                >
                  <td>{{ item.price }}</td>
                  <td>{{ item.latLng }}</td>
                  <td>{{ item.address }}</td>
                  <td>{{ item.area }}</td>
                  <td>{{ item.postalCode }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-fade-transition>
      </v-col>
    </v-row>
  </v-container>
  <!--  <section>-->
  <!--    <button @click="getDataButtonPressed">GET DATA</button>-->
  <!--    <textarea v-model="result"></textarea>-->
  <!--  </section>-->
</template>

<script>

export default {
  name: "RealtorCa",
  data() {
    return {
      result: null,
      rawResult: null,
      dataNeeded: null,
      scrapeTypes: ['LatLng For GoogleAds', 'Postal Codes', 'LatLng + Address'],
      province: null,
      loading: false,
      opts: {
        LongitudeMin: null,
        LongitudeMax: null,
        LatitudeMin: null,
        LatitudeMax: null,
        PriceMin: 3000000,
        PriceMax: 9999999999,
        BuildingTypeId: 0,
        CurrentPage: 1,
        RecordsPerPage: 200,
      },
      provinces: ['BC', 'ON', 'QC', 'NB', 'NF', 'NS', 'MB', 'SK', 'AB']
    }
  },
  methods: {
    getPrevPage() {
      this.opts.CurrentPage = this.opts.CurrentPage - 1
      this.getDataButtonPressed()
    },
    getNextPage() {
      this.opts.CurrentPage = this.opts.CurrentPage + 1
      this.getDataButtonPressed()
    },
    updateLngLat(province) {
      switch (province) {
        case 'BC':
          this.opts.LongitudeMax = -120.088719
          this.opts.LongitudeMin = -130.7628177611752
          this.opts.LatitudeMax = 59.955010
          this.opts.LatitudeMin = 49.061598
          break;
        case 'ON':
          this.opts.LongitudeMax = -76.04316647
          this.opts.LongitudeMin = -93.93992946
          this.opts.LatitudeMax = 55.55050497
          this.opts.LatitudeMin = 42.02920511
          break;
        case 'QC':
          this.opts.LongitudeMax = -67.91765258164308
          this.opts.LongitudeMin = -78.92668292374513
          this.opts.LatitudeMax = 55.127137148972814
          this.opts.LatitudeMin = 43.86493610154483
          break;
        case 'NB':
          this.opts.LongitudeMax = -64.802000
          this.opts.LongitudeMin = -69.38236
          this.opts.LatitudeMax = 48.018949
          this.opts.LatitudeMin = 45.12066
          break;
        case 'NF':
          this.opts.LongitudeMax = -50.33076
          this.opts.LongitudeMin = -66.34883
          this.opts.LatitudeMax = 56.83498
          this.opts.LatitudeMin = 51.77921
          break;
        case 'NS':
          this.opts.LongitudeMax = -58.40545
          this.opts.LongitudeMin = -66.41448
          this.opts.LatitudeMax = 46.57872
          this.opts.LatitudeMin = 43.51374
          break;
        case 'MB':
          this.opts.LongitudeMax = -94.99117836804294
          this.opts.LongitudeMin = -101.45722255558871
          this.opts.LatitudeMax = 59.83170364681165
          this.opts.LatitudeMin = 49.10344818685868
          break;
        case 'SK':
          this.opts.LongitudeMax = -102.21140608639621
          this.opts.LongitudeMin = -109.91389498562717
          this.opts.LatitudeMax = 59.8358765509817
          this.opts.LatitudeMin = 49.03833759957546
          break;
        case 'AB':
          this.opts.LongitudeMax = -110.32817289681338
          this.opts.LongitudeMin = -119.7155074820032
          this.opts.LatitudeMax = 59.7688339377471
          this.opts.LatitudeMin = 49.346492432356506
          break;
      }
    },
    async getDataButtonPressed() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) {
        return false
      }
      this.loading = true

      const realtor = require('realtorca');

      console.log(realtor.buildUrl(this.opts));
      //https://www.realtor.ca/Residential/Map.aspx#LongitudeMin=-79.6758985519409&LongitudeMax=-79.6079635620117&LatitudeMin=43.57601549736786&LatitudeMax=43.602250137362276&PriceMin=100000&PriceMax=425000

      // Parse options from url
      console.log(realtor.optionsFromUrl(realtor.buildUrl(this.opts)));


      realtor.post(this.opts)
          .then(data => {
            //json response
            console.log(data)
            this.rawResult = data
            this.loading = false
            this.parseData(data)
          })
          .catch(err => {
            console.log(err)
          });
    },
    parseData(data) {
      switch (this.dataNeeded) {
        case 'LatLng For GoogleAds':
          this.result = this.latLngGoogleAds(data)
          break;
        case 'Postal Codes':
          this.result = this.postalCodes(data)
          break;
        case 'LatLng + Address':
          this.result = this.latLngGoogleAdsAddress(data)
          break;
      }
    },
    postalCodes(data) {
      let arr = []
      data.Results.forEach(listing => {
        if (!arr.includes(listing.PostalCode) && listing.PostalCode[1] !== 'H') {
          if (this.province === 'ON') {
            if (listing.PostalCode[1] !== 'H') {
              arr.push(listing.PostalCode)
            }
          } else {
            arr.push(listing.PostalCode)
          }

        }
      })
      return arr
    },
    latLngGoogleAds(data) {
      let arr = []
      data.Results.forEach(listing => {
        let lat = listing.Property.Address.Latitude
        let lng = listing.Property.Address.Longitude
        // let obj = {lat, lng}
        // 1km:48.409395:-123.319951
        arr.push('(1km:' + lat + ':' + lng + ')')
      })
      return arr
    },
    latLngGoogleAdsAddress(data) {
      let arr = []
      data.Results.forEach(listing => {
        let lat = listing.Property.Address.Latitude
        let lng = listing.Property.Address.Longitude
        // let obj = {lat, lng}
        // 1km:48.409395:-123.319951
        const addressArray1 = listing.Property.Address.AddressText.split("|")

        let area = null
        let postalCode = null
        if (addressArray1[1]) {
          const addressArray2 = addressArray1[1].split(",")
          area = addressArray2[0]
          if (addressArray2[1]) {
            const addressArray3 = addressArray2[1].split(" ")
            postalCode = addressArray3.at(-1)
          }
        }
        
        let obj = {
          price: listing.Property.Price,
          latLng: '(1km:' + lat + ':' + lng + ')',
          address: addressArray1[0],
          area: area,
          postalCode: postalCode,
          type: listing.Property.type
        }
        arr.push(obj)
      })
      return arr
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    }
  }
}
</script>

<style scoped>

</style>
